import { SET_MASTERDATA } from "../actions/constants";
import { organisationId, ORGANISATION_LOGO_URL } from "../globals";

const defaultState = {
  organisation_id: organisationId,
  logo_url: '',
};

export default function (state: any = defaultState, action) {
  switch (action.type) {
    case SET_MASTERDATA: {
      return {
        ...state,
        ...action.data,
      };
    }
    default:
      return state;
  }
}
