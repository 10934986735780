import apiProvider from "./provider";
import { getAuthenticationHeaders, getErrorMessage } from "./utils";
import { PROJECT_X_URL } from "../globals";
import {
  CREATE_BOOKING,
  FETCH_AREA_CODES,
  FETCH_CITY_LIST,
  FETCH_SERVICE_TYPES,
  GET_ORGANISATION_DETAILS,
} from "./routes";
import { GET } from "./apiHandler";

export const bookingRequest = async (body) => {
  try {
    const response = await apiProvider.post(
      `${PROJECT_X_URL}${CREATE_BOOKING}`,
      body,
      {
        headers: getAuthenticationHeaders(),
      },
    );
    return {
      data: response.data.data,
      isSuccess: true,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};

export const fetchOrganisationDetails = async (headers) => {
  return GET(`${PROJECT_X_URL}${GET_ORGANISATION_DETAILS}`, {}, headers);
};

export const fetchAddressNodes = async (params) => {
  return GET(`${PROJECT_X_URL}${FETCH_CITY_LIST}`, params);
};

export const fetchAreaCodesForCity = async (params) => {
  return GET(`${PROJECT_X_URL}${FETCH_AREA_CODES}`, params);
};

export const fetchServiceTypes = async () => {
  return GET(`${PROJECT_X_URL}${FETCH_SERVICE_TYPES}`);
};
