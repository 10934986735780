import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Radio, Button, Modal, InputNumber, Row, Col, DatePicker, message } from 'antd';
import { createUseStyles } from 'react-jss';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  ORGANISATION_LOGO_URL,
  GOOGLE_RECAPTCHA_SITE_KEY,
  zajilServiceTypes,
  validOriginAreaCodes,
  validAreaCodes,
  hardcodedDataOrgs,
  hideAreaCodeOrgs,
} from '../../../globals';
import { bookingRequest, fetchAddressNodes, fetchAreaCodesForCity, fetchServiceTypes } from '../../../api/zajilBooking';
import zajilCityTranslations from '../../../translations/cityList.json';
import { useIntl } from 'react-intl';
import { setPageLanguage } from '../../../actions/performTranslation';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { range } from '../../../utils/utils'
import { connect } from 'react-redux';


const styles = createUseStyles({
  header: {
    padding: '8px 24px',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.4)',
    fontSize: 16,
    fontWeight: 600,
    color: '#424242',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    background: '#ffffff',
    zIndex: +1
  },

  bookingFormFields: {
    padding: 36,
    boxSizing: 'border-box',
    '& label::after': {
      content: '"" !important'
    },
    '& label.ant-form-item-required::before': {
      display: 'none'
    },
    '& label.ant-form-item-required::after': {
      display: 'inline-block',
      marginRight: '4px',
      color: '#ff4d4f',
      fontSize: '14px',
      lineHeight: 1,
      content: '"*" !important',
    },
    '& label.ant-radio-wrapper': {
      minWidth: '120px',
    },
    '& .ant-form-item-control-input': {
      maxWidth: '400px',
    },
    '& .ant-input-number ': {
      width: '100%'
    }
  },

  phoneNumberField: {
    '& .ant-input-group-rtl': {
      direction: 'ltr',
      borderLeft: '1px solid #d9d9d9',
    }
  },

  formSectionHeader: {
    fontSize: 16,
    fontWeight: 600,
    color: '#424242',
    marginBottom: 20,
  },
});

var config = 
{'allowed_language_list': ['ar','en'],
'document_types': [],
'hide_packing_required': false,
'is_packing_required': true,
'phone_number_code': "+966",
'show_consular_data': false,
'show_consular_office': false,
'show_customer_id':false,}

const BookingForm = (props: any) => {
  const intl = useIntl();
  const classes = styles();
  const { Option } = Select;
  const [googleCaptha, setGoogleCaptcha] = useState(null);
  const [captchaError, setCaptchaError] = useState('');
  const [processingSubmit, setProcessingSubmit] = useState(false);
  const [formReset, setFormReset] = useState(0);
  const language = useSelector((state:any) => state.translation.language);
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [useHardcodedData, setUseHardcodedData] = useState(true);
  const masterData = useSelector((state:any) => state.masterdata);
  const [cityList, setCityList] = useState<any>([]);
  const [areaCodesOrigin, setAreaCodesOrigin] = useState<any>([]);
  const [areaCodesDest, setAreaCodesDest] = useState<any>([]);
  const [serviceTypes, setServiceTypes] = useState<any>([]);
  const [hideAreaCodes, setHideAreaCodes] = useState(false);

  useEffect(() => {
    setGoogleCaptcha(null);
  }, [language]);

  useEffect(() => {
    const hardCodedData = !masterData.organisation_name || hardcodedDataOrgs.indexOf(masterData.organisation_name?.toUpperCase()) !== -1 ? true : false;
    const hideAreaCodes = hideAreaCodeOrgs.indexOf(masterData.organisation_name?.toUpperCase()) !== -1 ? true : false;
    setUseHardcodedData(hardCodedData);
    setHideAreaCodes(hideAreaCodes);
  }, [masterData]);

  useEffect(() => {
    !useHardcodedData && fetchInitialData();
  }, [useHardcodedData]);

  const fetchInitialData = async () => {
    const response = await fetchAddressNodes({
      hierarchy_id: 'L2',
    });
    if (response.isSuccess) {
      setCityList(response.data)
    } else {
      message.error(response.errorMessage);
    }
    const result = await fetchServiceTypes();
    if (result.isSuccess) {
      setServiceTypes(result.data.serviceTypes);
    } else {
      message.error(result.errorMessage);
    }
  }

  const fetchAreaCodes = async (city_code, type) => {
    const response = await fetchAreaCodesForCity({
      city_code,
      hierarchy_id: 'L1',
    });
    if (response.isSuccess) {
      type === 'origin_pincode' ? setAreaCodesOrigin(response.data) : setAreaCodesDest(response.data);
    } else {
      message.error(response.errorMessage);
    }
  }

  const renderHardcodedCity = (name, codes) => {
    return (
      <Form.Item
          label={`${intl.formatMessage({ id: 'city' })} ::`}
          name={name}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'cityError' })
            }
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={intl.formatMessage({ id: 'city' })}
          >
            {codes.map(e => <Option style={{ textTransform: 'uppercase' }} value={e}>{intl.formatMessage({ id: `city.${e}` })}</Option>)}
          </Select>
        </Form.Item>
    )
  }

  const renderCityAndPincode = (cityName, pincodeName, areaCodesList) => {
    return (
      <div>
      <Form.Item
          label={`${intl.formatMessage({ id: 'city' })} ::`}
          name={cityName}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'cityError' })
            }
          ]}
        >
          <Select
            showSearch
            onChange={(val) => {
              !hideAreaCodes && fetchAreaCodes(val,pincodeName);
            }}
            optionFilterProp="children"
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={intl.formatMessage({ id: 'city' })}
          >
            {cityList.map(e => <Option style={{ textTransform: 'uppercase' }} value={e.city_code}>{e.city_name}</Option>)}
          </Select>
        </Form.Item>
        {!hideAreaCodes && <Form.Item
        label="Area Code"
        name={pincodeName}
        rules={[
          {
            required: true,
          }
        ]}
      >
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input: string, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder="Area Code"
        >
            {areaCodesList.map(e => <Option style={{ textTransform: 'uppercase' }} value={e.city_code}>
             { `${e.city_name} (${e.parent_name})`}
            </Option>)}
        </Select>
      </Form.Item>}
      </div>
    )
  }

  const renderRecipientDetails = () => {
    return (
      <Form.Item
          label={`${intl.formatMessage({ id: 'name' })} ::`}
          name="destination_name"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder={intl.formatMessage({ id: 'name' })} />
        </Form.Item>
    );
  }

  const renderConsularOfficeAddressDropodown = () => {
    const consularAddressList = masterData?.booking_portal_config?.consular_address_list ? 
    masterData.booking_portal_config.consular_address_list: [];
    return (
      <Form.Item
          label={`${intl.formatMessage({ id:'consularOffice' })}`}
          name="destination_name"
          rules={[
            {
              required: true,
            },
          ]}
      >
          <Select
            showSearch
            onSelect={(val: string, option:any)=> {
                form.setFieldsValue({destination_address: val});
            }}
            placeholder={intl.formatMessage({ id: 'consularOffice' })}
          >
            { consularAddressList &&
              consularAddressList.map(e => <Option style={{ textTransform: 'uppercase' }} value={e}>{e}</Option>)
            }
          </Select>
      </Form.Item>
    );
  }

  const submitForm = async (values: any) => {
    setProcessingSubmit(true);
    const captcha = googleCaptha;
    if (!captcha) {
      setCaptchaError(intl.formatMessage({ id: 'captchaError' }));
      setProcessingSubmit(false);
      return;
    }
    if (moment.now() > values.pickup_time) {
      modal.error({
        title: 'Could Not Process Request',
        content: 'Pickup Time should be after current time',
        onOk() {
          setProcessingSubmit(false);
        }
      });
      return;
    }
    const requestData = {
      origin_details: {
        name: values.origin_name,
        phone: `${config.phone_number_code}${values.origin_phone}`,
        city: useHardcodedData ? zajilCityTranslations['en'][`city.${values.origin_pincode}`] : values.origin_city,
        pincode: hideAreaCodes ? values.origin_city : values.origin_pincode,
        address_line_1: values.origin_address,
      },
      destination_details: {
        name: values.destination_name,
        phone: `${config.phone_number_code}${values.destination_phone}`,
        city: useHardcodedData ? zajilCityTranslations['en'][`city.${values.destination_pincode}`] : values.destination_city,
        pincode: hideAreaCodes ? values.destination_city : values.destination_pincode,
        address_line_1: values.destination_address,
      },
      load_type: values.load_type,
      service_type_id: values.service_type_id.toUpperCase(),
      payment_type: values.payment_type,
      weight: Number(values.weight),
      time_slot_start: values.pickup_time.format('YYYY-MM-DD HH:00'),
      declared_value: Number(values.declared_value),
      is_packaging_required: config.hide_packing_required && !!values.is_packaging_required,
      national_id: values.national_id,
      captcha,
      num_pieces: Number(values.num_pieces),
      description: values.description,
    }
    // console.log(requestData);
    const response = await bookingRequest(requestData);
    if (response.isSuccess) {
      modal.success({
        title: 'Booking confirmed',
        content: (<p>{intl.formatMessage({ id: 'bookingSuccess' })} <b>{response.data.reference_number}</b></p>),
        onOk() {
          form.resetFields();
          setFormReset(Number(!formReset));
          setProcessingSubmit(false);
        },
      });
    }
    else {
      modal.error({
        title: 'Could Not Process Request',
        content: response.errorMessage,
        onOk() {
          setProcessingSubmit(false);
        }
      });
    }
  }

  const captchaChange = (captchaVal: any) => {
    setGoogleCaptcha(captchaVal);
    if (captchaVal && captchaError) {
      setCaptchaError('');
    }
  };

  function disabledDate(current) {
    // Can not select days before today
    return current < moment().startOf('day');
  }

  function disabledDateTime(date) {
    return {
      disabledHours: () => date > moment.now() ? [] : range(0, moment().format('HH')),
    };
  }

  return (
    <Form
      form={form}
      onFinish={submitForm}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 12 }}
      labelAlign="left"
    >
      {contextHolder}
      <div className={classes.bookingFormFields}>
        <div className={classes.formSectionHeader}>{intl.formatMessage({ id: 'shipperDetails' })} :</div>
        <Form.Item
          label={`${intl.formatMessage({ id: 'name' })} ::`}
          name="origin_name"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder={intl.formatMessage({ id: 'name' })} />
        </Form.Item>
        <Form.Item
          label={`${intl.formatMessage({ id: config.show_customer_id ? 'customerId' : 'nationalId' })} ::`}
          name="national_id"
          rules={[
            {
              required: true,
              pattern: new RegExp(config.show_customer_id ? "^[a-zA-Z0-9]*$" : "^[0-9]{10}$"),
              message: intl.formatMessage({ id: config.show_customer_id ? 'customerIdErrorPhil' : 'nationalIdError' }),
            },
          ]}
        >
          <Input placeholder={intl.formatMessage({ id: config.show_customer_id ? 'customerId' : 'nationalId' })} />
        </Form.Item>
        <Form.Item
          label={`${intl.formatMessage({ id: 'phoneNumber' })} ::`}
          name="origin_phone"
          rules={[
            {
              required: true,
              pattern: new RegExp( config.phone_number_code === "+63" ? "^[0-9]{10}$" : "^[1-9][0-9]{8}$"),
              message: intl.formatMessage({ id: config.phone_number_code === "+63" ? 'phNoErrorPhil' : 'phoneNumberError' })
            },
          ]}
        >
          <Input className={classes.phoneNumberField} 
          addonBefore={config.phone_number_code}
          placeholder={intl.formatMessage({ id: 'phoneNumber' })} />
        </Form.Item>
        {useHardcodedData ?
        renderHardcodedCity('origin_pincode', validOriginAreaCodes) : renderCityAndPincode('origin_city','origin_pincode',areaCodesOrigin)}
        <Form.Item
          label={`${intl.formatMessage({ id: 'address' })} ::`}
          name="origin_address"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'addressError' }),
            }
          ]}
        >
          <Input.TextArea placeholder={intl.formatMessage({ id: 'address' })} />
        </Form.Item>

        {config.document_types.length > 0 && <Form.Item
          label={`${intl.formatMessage({ id: 'typeOfDocuments' })} ::`}
          name="type_of_document"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'typeOfDocumentsError' }),
            }
          ]}
        >
          <Select
          mode="multiple"
          allowClear
          placeholder="Select the type of document"
          >
            {config.document_types.map(e => <Option value={e}>{e}</Option>)}
          </Select>
        </Form.Item>}

        <div className={classes.formSectionHeader} style={{ marginTop: '36px' }}>
          {intl.formatMessage({id: config.show_consular_data ? 'consularData' : 'recipientDetails' })} :
        </div>
        
        { config.show_consular_data ?
          renderConsularOfficeAddressDropodown():
          renderRecipientDetails()
        }
        <Form.Item
          label={`${intl.formatMessage({ id: 'phoneNumber' })} ::`}
          name="destination_phone"
          rules={[
            {
              required: config.show_consular_data ? false: true,
              pattern: new RegExp( config.phone_number_code === "+63" ? "^[0-9]{10}$" : "^[1-9][0-9]{8}$"),
              message: intl.formatMessage({ id: config.phone_number_code === "+63" ? 'phNoErrorPhil' : 'phoneNumberError' })
            },
          ]}
        >
          <Input 
            className={classes.phoneNumberField} 
            addonBefore={config.phone_number_code}
            placeholder={intl.formatMessage({ id: 'phoneNumber' })} />
        </Form.Item>
        {useHardcodedData ?
        renderHardcodedCity('destination_pincode', validAreaCodes) : renderCityAndPincode('destination_city','destination_pincode',areaCodesDest)}
        <Form.Item
          label={`${intl.formatMessage({ id: 'address' })} ::`}
          name="destination_address"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'addressError' }),
            }
          ]}
        >
          <Input.TextArea placeholder={intl.formatMessage({ id: 'address' })} />
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'pickupTime' })} ::`}
          name="pickup_time"
          rules={[{ required: true, message: intl.formatMessage({ id: 'pickupTimeError' }) }]}
        >
          <DatePicker
            format='YYYY-MM-DD HH'
            style={{ width: '160px' }}
            placeholder={intl.formatMessage({ id: 'pickupTime' })}
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
            showTime={{ defaultValue: moment(moment().format('HH'), 'HH') }}
          />
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'goodsValue' })} ::`}
          name="declared_value"
          rules={[
            {
              required: true,
              pattern: new RegExp("^[0-9]*$"),
              message: intl.formatMessage({ id: 'goodsValueError' }),
            },
          ]}
        >
          <Input placeholder={intl.formatMessage({ id: 'goodsValue' })} />
        </Form.Item>

        {config.is_packing_required && <Form.Item
          label={`${intl.formatMessage({ id: 'isPackagingRequired' })} ::`}
          name="is_packaging_required"
          rules={[{ required: false }]}
        >
          <Radio.Group>
            <Radio value={true}>{intl.formatMessage({ id: 'yes' })}</Radio>
            <Radio value={false}>{intl.formatMessage({ id: 'no' })}</Radio>
          </Radio.Group>
        </Form.Item>}

        { useHardcodedData ? <Form.Item
          label={`${intl.formatMessage({ id: 'serviceType' })} ::`}
          name="service_type_id"
          rules={[{ required: true, message: intl.formatMessage({ id: 'serviceTypeError' }) }]}
        >
          <Radio.Group>
            {zajilServiceTypes.map(e => <Radio value={e.id}>{intl.formatMessage({ id: e.id })}</Radio>)}
          </Radio.Group>
        </Form.Item> : <Form.Item
          label={`${intl.formatMessage({ id: 'serviceType' })} ::`}
          name="service_type_id"
          rules={[{ required: true, message: intl.formatMessage({ id: 'serviceTypeError' }) }]}
        >
          <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input: string, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder={`${intl.formatMessage({ id: 'serviceType' })}`}
        >
          {serviceTypes.map(e => <Option style={{ textTransform: 'uppercase' }} value={e.id}>{e.name}</Option>)}
        </Select>
        </Form.Item>}

        <Form.Item
          label={`${intl.formatMessage({ id: 'paymentCollection' })} ::`}
          name="payment_type"
          rules={[{ required: true, message: intl.formatMessage({ id: 'paymentCollectionError' }) }]}
        >
          <Radio.Group>
            <Radio value="PICKUP">{intl.formatMessage({ id: 'pickup' })}</Radio>
            <Radio value="DELIVERY">{intl.formatMessage({ id: 'delivery' })}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'shipmentType' })} ::`}
          name="load_type"
          rules={[{ required: true, message: intl.formatMessage({ id: 'shipmentTypeError' }) }]}
        >
          <Radio.Group>
            <Radio value="DOCUMENT">{intl.formatMessage({ id: 'document' })}</Radio>
            <Radio value="NON-DOCUMENT">{intl.formatMessage({ id: 'nonDocument' })}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'weightKgs' })} ::`}
          name="weight"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'weightError' })
            },
          ]}
        >
          <InputNumber max={30} min={0} placeholder={intl.formatMessage({ id: 'weightKgs' })} />
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'numberOfPieces' })} ::`}
          name="num_pieces"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'numberOfPiecesError' }),
            },
          ]}
        >
          <InputNumber min={1} placeholder={intl.formatMessage({ id: 'numberOfPieces' })} />
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({ id: 'shipmentDescription' })} ::`}
          name="description"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input maxLength={50} placeholder={intl.formatMessage({ id: 'shipmentDescription' })} />
        </Form.Item>

        <Form.Item
          label=":"
        >
          <div>
            <ReCAPTCHA
              sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
              onChange={captchaChange}
              hl={language}
              key={`${language}${formReset}`}
            />
            <span style={{ color: 'red' }}>{captchaError}</span>
          </div>
        </Form.Item>
        <Form.Item
          label=":"
        >
          <Button style={{ width: "100%" }} size="large" type="primary" htmlType="submit" loading={processingSubmit} >
            {intl.formatMessage({ id: 'submit' })}
          </Button>
        </Form.Item>
      </div>
    </Form>);
};

const ZajilEBooking = (props: any) => {
  const classes = styles();
  const intl = useIntl();
  const language = useSelector((state:any) => state.translation.language);
  const dispatch = useDispatch();
  const masterData = useSelector((state:any) => state.masterdata);
  const handleLanguageChange = e => {
    dispatch(setPageLanguage(e.target.value));
  };

  config = {...config,...masterData.booking_portal_config};
  if(config.allowed_language_list.length === 1)
    dispatch(setPageLanguage(config.allowed_language_list[0]));

  return (
    <div style={{ marginTop: '56px' }} >
      <Row justify="space-between" className={classes.header}>
        <Col>
          <div>
            {masterData.logo_url && <img alt="Organisation Logo" style={{ height: 44, margin: '0px 8px' }} src={masterData.logo_url} />}
            <span style={{ verticalAlign: 'middle' }}>{intl.formatMessage({ id: 'header' })}</span>
          </div>
        </Col>
        <Col>
          <div>
          {config.allowed_language_list.length > 0 && <Radio.Group value={language} onChange={handleLanguageChange}>
            {config.allowed_language_list.map(e => <Radio.Button value={e}>{intl.formatMessage({ id: e })}</Radio.Button>)}
          </Radio.Group>}
          </div>
        </Col>
      </Row>
      <BookingForm />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    masterdata: state.masterdata,
  };
};

export default connect(mapStateToProps)(ZajilEBooking);
