import {
    SET_PAGE_LANGUAGE,
} from '../actions/constants';

const defaultState = {
    language: 'en'
};

export default function (state: any = defaultState, action) {

    switch (action.type) {

        case SET_PAGE_LANGUAGE: {
            return {
                ...state,
                language: action.language
            };
        }
        default: return state;

    }
}
